import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, UserCircleIcon } from '@heroicons/react/24/outline';

function Testimonial() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials = [
        {
            name: "Professor Alex Turner",
            occupation: "Academic Researcher",
            testimony: "WorkPress is a revolutionary AI-powered Research Copilot. It has significantly enhanced my research efficiency, providing invaluable insights and saving me valuable time. A must-have for any serious researcher!"
        },
        {
            name: "Dr. Emily Chen",
            occupation: "Technology Innovator",
            testimony: "WorkPress has redefined the way I approach academic research. Its AI capabilities simplify complex topics, making my exploration of cutting-edge technologies more accessible. A game-changer for tech enthusiasts!"
        },
        {
            name: "Sarah Thompson",
            occupation: "Business Analyst",
            testimony: "I rely on WorkPress for in-depth research in the business landscape. Its AI-powered assistance has streamlined my analysis process, offering clear explanations and enhancing my overall productivity. A crucial tool for business professionals!"
        },
        {
            name: "David Rodriguez",
            occupation: "Ph.D. Candidate",
            testimony: "WorkPress has been an indispensable companion in my doctoral journey. Its AI insights have provided clarity on intricate research topics, accelerating my progress and elevating the quality of my work. Highly recommended for fellow researchers!"
        },
        {
            name: "Grace Liu",
            occupation: "Environmental Scientist",
            testimony: "Navigating environmental research has never been easier. WorkPress simplifies complex concepts, making it an essential tool for environmental scientists like myself. It's like having a knowledgeable copilot for my research endeavors!"
        },
        {
            name: "Mark Foster",
            occupation: "AI Enthusiast",
            testimony: "WorkPress combines the power of AI with research expertise seamlessly. It's a go-to platform for anyone passionate about artificial intelligence, offering insightful explanations and making complex topics digestible. An impressive AI research companion!"
        }
    ];
    

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handleBack = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    useEffect(() => {
        const autoNextTimer = setTimeout(() => {
            handleNext();
        }, 3500);

        return () => {
            clearTimeout(autoNextTimer);
        };
    }, [currentIndex]);

    return (
        <div className='flex items-center justify-center gap-2 my-5'>
            <ChevronLeftIcon className='w-8 h-8 cursor-pointer' onClick={handleBack} />
            <div className='space-y-3 max-w-2xl '>
                <p className='w-[80%] h-[100px] m-auto text-center text-sm'>
                    {testimonials[currentIndex].testimony}
                </p>
                <div className='flex items-center gap-6 justify-center'>
                    <div className='flex items-center gap-2'>
                    <UserCircleIcon className='w-5 h-5' />
                    <span className='text-xs'>
                        {testimonials[currentIndex].name}
                    </span>
                    </div>
                 <span className='text-gray-400'>
                    |
                 </span>
                    <span className='text-xs'>
                        {testimonials[currentIndex].occupation}
                    </span>
                </div>
            </div>
            <ChevronRightIcon className='w-8 h-8 cursor-pointer' onClick={handleNext} />
        </div>
    );
}

export default Testimonial;
