import { useState, useEffect } from "react";

const CONTEXT_KEY = process.env.CONTEXT_KEY || "121090a271b7a44e4";
const API_KEY = process.env.CUSTOM_SEARCH_API_KEY || "AIzaSyA_rU_-qaBJwq4cSNv7aEj6fAz4_GlRgXI";

const useGoogleSearch = (term) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      fetch(
        `https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${CONTEXT_KEY}&q=${term} filetype:pdf`
      )
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          setError(null);
        })
        .catch((e) => {
          console.log(e);
          setError("Something went wrong !!");
        });

      setLoading(false);
    };

    fetchData();
  }, [term]);

  return { data, error, loading };
};

export default useGoogleSearch;
