"use client";

import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { noop } from "../utils/index";
import Loader from "./Loader";

const InputFields = ({
  searchQuery,
  setSearchQuery,
  fetchResponse,
  fileName,
  searchLoader,
  textRef,
  setUserTyping,
}) => {
  const memoizedFetchResponse = useCallback(() => {
    if (searchQuery.length > 0) {
      fetchResponse();
    }
  }, [searchQuery, fetchResponse]);

  return (
    <div className="relative w-[96%] mx-auto mb-3">
      <div className="absolute top-1/2 left-3  -translate-y-1/2">
        {/* <img
          src={searchQuery.length === 0 ? '/icons/search.png' : '/icons/searchactive.png'}
          alt=""
          className="h-5 w-5 transition-all duration-150"
        /> */}
      </div>
      <input
        onKeyDown={(e) => {
          if (e.key === "Enter") memoizedFetchResponse();
        }}
        name="search"
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setUserTyping(true);
        }}
        onBlur={() => {
          setUserTyping(false);
        }}
        value={searchQuery}
        ref={textRef}
        autoComplete="off"
        spellCheck={false}
        type="text"
        className="h-14 w-full pl-10 pr-20 rounded-lg border z-0 focus:shadow focus:outline-none"
        placeholder="Try summarizing the pdf..."
      />

      <div className="absolute top-2 right-2">
        <button
          type="button"
          onClick={memoizedFetchResponse}
          disabled={searchQuery.length === 0}
          className="h-10 w-20 text-white rounded-lg bg-gray-400 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:hover:bg-gray-400 transition-all duration-150"
        >
          {searchLoader ? <Loader size={20} /> : "Search"}
        </button>
      </div>
    </div>
  );
};

InputFields.propTypes = {
  searchQuery: PropTypes.string,
  fetchResponse: PropTypes.func,
  fileName: PropTypes.string,
  searchLoader: PropTypes.bool,
  textRef: PropTypes.instanceOf(Object),
};

InputFields.defaultProps = {
  searchQuery: "",
  fetchResponse: noop,
  fileName: "",
  searchLoader: false,
  textRef: {},
};

export default InputFields;
