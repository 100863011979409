import React from "react";

const Footer = () => {
  return (
    <footer className="grid grid-cols-5 place-content-start place-items-start px-6 py-12 bg-[#01293b] w-full text-md text-gray-500">
      <div className="flex flex-col h-full justify-between items-start">
        <img src="/workpresslogo.jpg" className="w-30  object-contain h-10" alt="logo" />

        <p>© 2023 | PubGenius Inc.</p>
      </div>
      <div className="">
        <h3 className="text-lg text-white">WorkPress</h3>
        <ul className="space-y-2 mt-2">
          <li>About</li>
          <li>Careers</li>

          <li>Resources</li>

          <li>Supportx</li>
          <li>Browse Papers</li>
          <li>Pricing</li>
          <li>
            SciSpace Affiliate <br /> Program Cancellation
          </li>
          <li>Refund Policy</li>
        </ul>
      </div>
      <div className="">
        <h3 className="text-lg text-white">Tools</h3>
        <ul className="space-y-2 mt-2">
          <li>Citation generator</li>
          <li>AI Detector</li>

          <li>Paraphraser</li>

          <li>Supportx</li>
          <li>SciSpace</li>
        </ul>
      </div>
      <div className="">
        <h3 className="text-lg text-white">Directories</h3>
        <ul className="space-y-2 mt-2">
          <li>Papers</li>
          <li>Topics</li>

          <li>Journals</li>

          <li>Authors</li>
          <li>Conferences</li>
          <li>Institutions</li>
          <li>Questions</li>
          <li>Citation Styles </li>
        </ul>
      </div>
      <div>
        <h3 className="text-lg text-white">Contact</h3>
        <ul className="space-y-2 mt-2">
          <li>admin@workpress.in</li>
          {/* <li>+91 8431021544</li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
