import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./SearchPage.css";
import { useStateValue } from "../StateProvider";
import useGoogleSearch from "../components/useGoogleSearch";
import Search from "../components/Search";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import RoomIcon from "@mui/icons-material/Room";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../components/Sidebar";

function SearchPage() {
  const [{ term }] = useStateValue();
  const [visible, setVisible] = useState(false);
  const { data, error, loading } = useGoogleSearch(term); // Live API Call
  const params = useParams();
  const navigate = useNavigate();
  const stateParam = new URLSearchParams(window.location.search).get("q");
  const [showChat, setShowChat] = useState(true);

  return (
    <div className="searchPage">
      <Sidebar
        visible={visible}
        setVisible={setVisible}
        showChat={showChat}
        setShowChat={setShowChat}
      />

      <div className="flex items-center p-5 gap-8">
        <Link to="/">
          <img
            className="w-30 h-10 object-contain"
            src="/workpresslogo.jpg"
            alt="googlelogo"
            style={{ objectFit: "contain" }}
          />
        </Link>
        <Search hideButtons stateParam={stateParam} />
      </div>

      {loading && (
        <div className="flex w-full h-full items-center justify-center flex-col">
          <span className="loading loading-spinner text-success loading-lg"></span>
        </div>
      )}

      {term && (
        <div className="searchPage__results">
          <p className="searchPage__resultCount">
            About {data?.searchInformation?.formattedTotalResults} results (
            {data?.searchInformation?.formattedSearchTime} seconds) for <strong>{term}</strong>
          </p>

          {data?.items?.map((item, id) => (
            <div key={id} className="searchPage__result">
              <p
                className="searchPage__displayLink"
                onClick={() => {
                  // setPdf(item.link);
                  params.q = stateParam;
                  params.pdf = item.link;
                  navigate(`/search?q=${stateParam}&pdf=${item.link}`);
                  setVisible(true);
                }}
              >
                {item.displayLink} ▽
              </p>
              <div className="searchPage__resultTitle">
                <h2
                  onClick={() => {
                    // setPdf(item.link);
                    params.q = stateParam;
                    params.pdf = item.link;
                    navigate(`/search?q=${stateParam}&pdf=${item.link}`);
                    setVisible(true);
                    // console.log("item clicked wiht link",item.link)
                  }}
                >
                  {item.title}
                </h2>
              </div>
              <p className="searchPage__resultSnippet">{item.snippet}</p>
            </div>
          ))}

          {error && (
            <div className="flex items-center justify-center flex-col">
              <p className="text-center">{error}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchPage;
