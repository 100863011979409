import React, { useEffect, useRef, useState } from "react";
import UploadandChat from "../minigator/UploadandChat";
import CloseIcon from "@mui/icons-material/Close";

const Sidebar = ({ visible, setVisible, showChat, setShowChat }) => {
  const [minWidth, maxWidth, defaultWidth] = [600, 1300, 800];
  const sidebarRef = useRef(null);
  const [width, setWidth] = useState(defaultWidth);
  const isResized = useRef(false);
  const [initialX, setInitialX] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isResized.current) {
        return;
      }

      const movementX = e.clientX - initialX; // Calculate the movement distance

      setWidth((previousWidth) => {
        const newWidth = previousWidth - movementX;

        const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth;

        return isWidthInRange ? newWidth : previousWidth;
      });

      setInitialX(e.clientX); // Update the initial X position for the next movement
    };

    const handleMouseUp = () => {
      isResized.current = false;
      // Restore text selection and cursor style
      document.body.style.userSelect = "auto";
      document.body.style.cursor = "auto";
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [minWidth, maxWidth, initialX]);

  const handleMouseDown = (e) => {
    isResized.current = true;
    setInitialX(e.clientX); // Store the initial X position when resizing starts
    // Disable text selection and set cursor style during resizing
    document.body.style.userSelect = "none";
    document.body.style.cursor = "col-resize";
  };

  return (
    <div className={`flex justify-end ${visible ? "visible" : "invisible"}`}>
      <div ref={sidebarRef} style={{ zIndex: 200 }} className={`sidebar fixed flex w-fit`}>
        <div className="w-2 text-black cursor-col-resize" onMouseDown={handleMouseDown} />
        <div className="flex justify-end h-fit">
          <CloseIcon
            className="cursor-pointer hover:text-red-500 z-50"
            onClick={() => setVisible(!visible)}
          />
        </div>
        <div className="flex flex-col w-full h-screen justify-end" style={{ width: `${width}px` }}>
          <div className="h-full w-full">
            <UploadandChat showChat={showChat} setShowChat={setShowChat} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
