import React, { useEffect, useRef, useState } from "react";
import "./Search.css";
import SearchIcon from "@mui/icons-material/Search";
import "animate.css";
import { useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../redux/reducer";

function Search({ hideButtons = false, stateParam }) {
  const [{ term }, dispatch] = useStateValue();
  const [input, setInput] = useState("");
  const inputRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  const samples = [
    "Green technologies in educational institutions",
    "E-learning effectiveness and student performance",
    "Entrepreneurship education and business success",
    "Role of schools in promoting environmental sustainability",
    "Cybersecurity awareness in educational institutions",
    "Impact of technology on business education",
  ];

  const search = (e) => {
    e.preventDefault();

    // params.q(`/search?q=${input}`);
    params.q = input;

    dispatch({
      // eslint-disable-next-line
      type: actionTypes.SET_SEARCH_TERM,
      term: input,
    });

    //input to output
    params.q = input;
    navigate(`/search?q=${input}`);
  };

  const searchSamples = (sample) => {


    // params.q(`/search?q=${input}`);
    params.q = sample;

    dispatch({
      // eslint-disable-next-line
      type: actionTypes.SET_SEARCH_TERM,
      term: sample,
    });

    //input to output
    params.q = sample;
    navigate(`/search?q=${sample}`);
  };

  useEffect(() => {
    if (stateParam) {
      setInput(stateParam);
    }
  }, []);

  const changePlaceHolder = () => {
    let placeholders = [
      "Type Something",
      "Try 'Cybersecurity'",
      "How about 'War Crisis' ",
      "Try 'JP Morgan'",
    ];

    // Use setTimeout to delay execution by 2 seconds
    setInterval(() => {
      // Get a random element from the array
      let randomPlaceholder =
        placeholders[Math.floor(Math.random() * placeholders.length)];

      // Set the random placeholder to an input field, for example
      inputRef.current.placeholder = randomPlaceholder;
    }, 4000);
  };

  useEffect(() => {
    changePlaceHolder();
  }, []);

  return (
    <form
      className="search"
      onSubmit={(e) => {
        search(e);
      }}
    >
      <div className="border-2 rounded-md px-2 w-[700px] flex items-center">
        <SearchIcon className="search__inputIcon" />
        <input
          value={input}
          className="w-full outline-none p-5 placeholder-shown: animate__animated placeholder-shown:animate__fadeInDown text-orange-500"
          id="prompt"
          ref={inputRef}
          onChange={(e) => setInput(e.target.value)}
        />
        {/* <MicIcon /> */}
      </div>
      {!hideButtons && (
        <div className="space-y-3 my-10">
          <p className="text-sm">Try asking or searching for:</p>
          <ul className="space-y-2">
            {samples.map((sample) => (
              <li className="space-x-2 cursor-pointer" onClick={()=>{
               searchSamples(sample)
              }}>
                <SearchIcon className="search__inputIcon" />
                <span className="text-md text-blue-500">{sample}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  );
}

export default Search;
