import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFileChat, getResponse, uploadPDFClient } from "./apiRequests";
import ChatRender from "./ChatRender";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { v4 as uuidv4 } from "uuid";
import { setLinks } from "../firebase/apiRequests";
import Loader from "./Loader";
import InputFields from "./InputFields";
import { Alert, Snackbar } from "@mui/material";

const MyDocViewer = ({ file }) => {
  // Your DocViewer implementation goes here
  return (
    <DocViewer
      pluginRenderers={DocViewerRenderers}
      documents={[
        {
          uri: window.URL.createObjectURL(file),
          fileName: file.name,
        },
      ]}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableHeader: true,
          overrideComponent: true,
          retainURLParams: true,
        },
      }}
      showToolbar={true}
      showPropertiesPane={false}
      enableToolbar={true}
    />
  );
};

// Memoize the DocViewer component
const MemoizedDocViewer = React.memo(MyDocViewer, (prevProps, nextProps) => {
  // Compare the file prop to determine whether the component should re-render
  return prevProps.file === nextProps.file;
});

const UploadandChat = ({ showChat, setShowChat }) => {
  const [indexname, setIndexname] = useState(null);
  const [busy, setBusy] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [file, setFile] = useState(null);
  const [searchLoader, setSearchLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  const textValue = useRef(null);
  const [pdfData, setPdfData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [userTyping, setUserTyping] = useState(false);
  const [apiTyping, setApiTyping] = useState(false);

  const stateParam = new URLSearchParams(window.location.search).get("pdf");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const fetchResponse = async () => {
    if (!indexname || !searchQuery || searchQuery.length === 0 || searchLoader) return;

    setSearchLoader(true);
    let question = searchQuery;
    setMessages((prev) => [
      ...prev,
      {
        type: "user",
        message: question,
      },
    ]);
    setUserTyping(false);
    setSearchQuery("");
    setApiTyping(true);
    const res = await getResponse(indexname, question);

    if (res) {
      setMessages((prev) => [
        ...prev,
        {
          type: "api",
          message: res?.content,
        },
      ]);
      setApiTyping(false);
      setSearchLoader(false);
    }
    else {
      toast.error("Internal Server Error");
      setSearchLoader(false);
      setApiTyping(false);
      setSearchQuery("");
      return;
    }
  };

  const uploadPDFHandler = useCallback(async (pdfUrl) => {
    try {
      setBusy(true);
      const formFile = pdfData;
      const response = await uploadPDFClient({
        url: pdfUrl,
      });
      console.log(response, "after uploading to chatpdf");

      if (response) {
        setBusy(false);
        setIndexname(response?.sourceId);
        setLinks({
          indexName: response?.sourceId,
          fileName: formFile.name,
        });
        setFile(formFile);
      } else {
        setBusy(false);
        toast.error("Internal Server Error");
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
      setBusy(false);
      toast.error(JSON.stringify(error));
    }
  }, [stateParam, indexname, pdfData]);

  const handleFetchPdfData = async (pdfUrl) => {
    try {
      setPdfLoading(true);
      setOpen(true);

      const response = await fetch('https://proxy.cors.sh/' + pdfUrl, {
        headers: {
          'x-cors-api-key': process.env.REACT_APP_CORS_KEY
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const file_name = Math.random().toString(36).substring(6) + "_name.pdf";
        const fileObj = await new File([blob], file_name, {
          type: "application/pdf",
        });

        const fileSizeInMB = fileObj.size / (1024 * 1024);

        if (fileSizeInMB > 20) {
          let answer = prompt(
            "This file is too large. Are you sure you want to proceed"
          );

          if (answer) {
            setFile(fileObj);
            setPdfData(fileObj);
          }
        }

        setFile(fileObj);
        setPdfData(fileObj);
      } else {
        toast.error("Failed to fetch PDF");
        console.error(`Failed to fetch PDF. Status code: ${response.status}`);
      }
    } catch (error) {
      toast.error("Failed to fetch PDF");
      console.error("Error:", error);
    }
    setPdfLoading(false);
  };

  useEffect(() => {
    if (stateParam) {
      handleFetchPdfData(stateParam);
      uploadPDFHandler(stateParam);
    }
  }, [stateParam]);

  return (
    <div className="w-full h-screen flex flex-col overflow-x-hidden pb-5">
      <ToastContainer />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          Please wait, we are loading results...
        </Alert>
      </Snackbar>
      <div className="flex flex-col items-center justify-center grow w-full h-[calc(100%-68px)]">
        <div className="w-full h-full flex border rounded-md shadow-md divide-x">
          <div className="w-full">
            {pdfLoading && (
              <div className="flex w-full h-full justify-center items-center">
                <Loader size={48} />
              </div>
            )}
            {!pdfLoading && (
              <div className="px-4 py-4 h-full">
                {file === null ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <label
                      htmlFor="file"
                      className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:text-gray-400 hover:shadow-xl"
                    >
                      <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <span className="mt-2 text-base leading-normal">
                        Select a file
                      </span>
                      <input
                        type="text"
                        value={stateParam}
                        id="file"
                      />
                      <button onClick={() => handleFetchPdfData(stateParam)}>
                        Upload
                      </button>
                    </label>
                  </div>
                ) : (
                  <div className="w-full relative">
                    {typeof file === "string" ? (
                      <div className="w-full h-full flex items-center justify-center capitalize font-semibold text-lg">
                        {file}
                      </div>
                    ) : (
                      <div className="h-[90vh]">
                        <MemoizedDocViewer file={file} />
                      </div>
                    )}

                    <div className="absolute z-50 top-0 right-0">
                      <div className="tooltip tooltip-bottom" data-tip={showChat ? "Hide Chat" : "Show Chat"}>
                        <input type="checkbox" className="toggle" checked={showChat} onChange={() => setShowChat(!showChat)} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {showChat && (
            <div className=" min-w-lg w-full h-full">
              <div className="flex flex-col h-full">
                <div className="h-full overflow-y-scroll noscrollbar">
                  {busy ? (
                    <div className="flex flex-col justify-center gap-4 w-full h-full items-center">
                      <Loader size={48} />
                      <p>Getting things geared up</p>
                    </div>
                  ) : (
                    <div className="w-full h-full px-4 pt-4 ">
                      <ChatRender chats={messages} fileName={file?.name} userTyping={userTyping} apiTyping={apiTyping} />
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  <InputFields
                    fetchResponse={fetchResponse}
                    searchLoader={searchLoader}
                    textRef={textValue}
                    fileName={indexname}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setUserTyping={setUserTyping}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadandChat;
