import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const randomColors_ai = ["chat-bubble-primary", "chat-bubble-secondary", "chat-bubble-accent"];
const randomColors_user = ["chat-bubble-info", "chat-bubble-success", "chat-bubble-warning"];

function getRandomElement(arr) {
  if (arr.length === 0) {
    return null; // Return null if the array is empty
  }
  const randomIndex = Math.floor(Math.random() * arr.length); // Generate a random index within the array length
  return arr[randomIndex]; // Return the element at the random index
}

const ChatRender = ({ chats, fileName, userTyping, apiTyping }) => {
  const chatEndRef = useRef(null);
  const [userColor, setUserColor] = useState(null);
  const [aiColor, setAiColor] = useState(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chats]);

  const getUserColor = () => {
    const color = getRandomElement(randomColors_user);
    setUserColor(color);
    return color;
  };

  const getAiColor = () => {
    const color = getRandomElement(randomColors_ai);
    setAiColor(color);
    return color;
  };

  return (
    <div className="w-full h-full overflow-y-scroll scrollbar-hide">
      {fileName === null && (
        <div className="flex items-center justify-center h-full w-full">
          Please Wait, the File Is Loading
        </div>
      )}
      {chats.length === 0 && fileName !== null && (
        <div className="flex items-center justify-center h-full w-full">
          Please type something to start the conversation
        </div>
      )}
      {chats.map((chat, index) =>
        chat.type === 'user' ? (
          <div className="chat chat-end" key={index}>
            <div className={`chat-bubble chat-bubble-success`}>{chat.message}</div>
          </div>
        ) : (
          <div className="chat chat-start mb-4" key={index}>
            <div className={`chat-bubble chat-bubble-info`}>{chat.message}</div>
          </div>
        )
      )}
      {
        userTyping && <div className="chat chat-end" >
          <div className={`chat-bubble chat-bubble-success`}>
            <span className="loading loading-dots loading-xs"></span>
          </div>
        </div>
      }
      {
        apiTyping && <div className="chat chat-start mb-4" >
          <div className={`chat-bubble chat-bubble-info`}>
            <span className="loading loading-dots loading-xs"></span>
          </div>
        </div>
      }
      <div ref={chatEndRef} />
    </div>
  );
};

ChatRender.propTypes = {
  chats: PropTypes.instanceOf(Array),
  fileName: PropTypes.string,
};

ChatRender.defaultProps = {
  chats: [],
  fileName: '',
};

export default ChatRender;
