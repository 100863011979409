import React from 'react';
import './Home.css';
import Search from '../components/Search';
import Header from '../components/common/Header';
import Typewriter from 'typewriter-effect';
import Testimonial from '../components/Testimonial';
// import PopularSection from '../components/PopularSection';
import Footer from '../components/common/Footer';




function Home() {
  return (
    <div className='home'>

      <Header/>
      
      <div className='flex flex-col items-center gap-5 justify-center w-full'>
          
         <h1 className='text-black text-3xl mt-6 font-semibold'>
         Your AI-powered Research Copilot
         </h1>

         <p className='max-w-[500px] text-center'>
         <Typewriter
         options={{
          autoStart:true,
          cursorClassName:"text-orange-500",
          delay:30,
        loop:true
         }}
  onInit={(typewriter) => {
    typewriter.typeString("Unlock Boundless Insights: Embrace AI as Your Research Navigator.")
      .pauseFor(500)
      .changeDeleteSpeed(1000)
      .deleteAll()
      .typeString("Effortless Excellence: Let AI Revolutionize Your Academic Journey.")
      .changeDeleteSpeed(1000)
      .deleteAll()
      .typeString("Beyond Today: Embracing AI for Future-Ready Research Exploration.")
      .start()
  }}
/>
         </p>

          
              <Search />
              <Testimonial/>
              {/* <PopularSection category={"Machine Learning"}/>
              <PopularSection category={"Machine Learning"}/>
              <PopularSection category={"Machine Learning"}/> */}
              <Footer/>
      </div>
    </div>
  )
}

export default Home;
