import { toast } from "react-hot-toast";
import axios from "axios";
import { APIURL } from "../config/index";

export const getDoc = async (indexName) => {
  try {
    let res = await fetch(
      `${APIURL}/list-documents?account_id=${process.env.REACT_APP_ACCOUNTID}&index_name=${indexName}`,
      {
        method: "GET",
        headers: {
          Authorization: process.env.REACT_APP_APITOKEN,
        },
      }
    );
    res = await res.json();
    return res;
  } catch (error) {
    toast.error("Internal Server Error");
    return error;
  }
};

export const getFileChat = async (x, y) => {
  try {
    let res = await fetch(`https://favcynavigator.pocoai.repl.co/filechat?x=${x}&y=${y}`, {
      method: "GET",
      // headers: {
      //   Authorization: process.env.NEXT_PUBLIC_APITOKEN,
      // },
    });
    res = await res.json();
    return res;
  } catch (error) {
    toast.error("Internal Server Error");
    return error;
  }
};

export const getResponse = async (indexName, inputSequence) => {
  // try {
  //   let res = await fetch(
  //     `${APIURL}/ask-open-ended?account_id=${process.env.REACT_APP_ACCOUNTID}&index_name=${indexName}&input_sequence=${inputSequence}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: process.env.REACT_APP_APITOKEN,
  //       },
  //     }
  //   );
  //   res = await res.json();
  //   return res;
  // } catch (error) {
  //   toast.error("Internal Server Error");
  //   return error;
  // }

  try {
    const response = await axios.post(
      `https://api.chatpdf.com/v1/chats/message`,
      {
        sourceId: indexName,
        messages: [
          {
            role: "user",
            content: inputSequence,
          },
        ],
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_CHATPDF_APIKEY,
          "Content-Type": "application/json",
        },
      }
    );

    // Handle the response data
    return response.data;
  } catch (error) {
    // Handle errors
    toast.error("Internal Server Error");
    return error?.response?.data || error;
  }
};

export const uploadPDFClient = async (formData) => {
  try {
    const response = await axios.post(`${APIURL}`, formData, {
      headers: {
        "x-api-key": process.env.REACT_APP_CHATPDF_APIKEY,
        "Content-Type": "application/json",
      },
    });

    // Handle the response data
    return response.data;
  } catch (error) {
    // Handle errors
    toast.error("Internal Server Error");
    return error?.response?.data || error;
  }
};
