import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import reducer, { initialState } from "./redux/reducer";
import { StateProvider } from "./StateProvider";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </StateProvider>
);

reportWebVitals();
